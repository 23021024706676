<template>
  <div class="d-flex flex-column flex-grow-1 rounded h-100">
    <BaseToolBar title="Documents" subtitle="Liste des documents disponibles"></BaseToolBar>
    <trame-uploader
      id="document_divers_trames"
      :file_system="'DIVERS'"
      title="Exportez à partir d'un modèle"
      :data="exportData"
      class="mr-md-3"
    ></trame-uploader>
    <base-form class="mr-md-3" shadow :updateBtn="false">
      <uploader
        :id="'document_accueil'"
        class="ml-md-3 mr-md-3"
        url="/upload/document"
        file_system="SIRH"
        :file_field="'collaborateur_accueil'"
        :file_key="$route.params.id"
        title="Documents d'accueil"
      ></uploader>
      <uploader
        :id="'document_divers'"
        class="ml-md-3 mr-md-3"
        url="/upload/document"
        file_system="SIRH"
        file_field="infos"
        :file_key="$route.params.id"
        title="Documents divers"
      ></uploader>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import BaseForm from "@/components/bases/Form.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import Uploader from "@/components/bases/Uploader.vue";
import TrameUploader from "@/components/application/TrameUploader.vue";

export default {
  components: {
    BaseToolBar,
    Uploader,
    BaseForm,
    TrameUploader,
  },
  computed: {
    ...mapFields("collaborateurs", [
      "collaborateur.collaborateur_matricule",
      "collaborateur.collaborateur_nom",
      "collaborateur.collaborateur_prenom",
      "collaborateur.collaborateur_date_naissance",
      "collaborateur.collaborateur_rinpp",
      "collaborateur.collaborateur_sexe_id",
      "collaborateur.collaborateur_nationalite_id",
      "collaborateur.collaborateur_adresse_naissance",
      "collaborateur.collaborateur_situation_famille_id",
      "collaborateur.collaborateur_enfants",
      "collaborateur.collaborateur_niveau_formation_id",
      "collaborateur.collaborateur_adresse",
    ]),
    exportData() {
      return {
        values: {
          nom: this.collaborateur_nom,
          prenom: this.collaborateur_prenom,
          nom_prenom: this.collaborateur_nom + " " + this.collaborateur_prenom,
          date_naissance: this.$moment(this.collaborateur_date_naissance).format("DD/MM/YYYY"),
          ville_naissance: this.collaborateur_adresse_naissance,
          sexe: this.collaborateur_sexe_id == 1 ? "M" : "F",
          date: this.$moment().format("DD/MM/YYYY"),
          collaborateur_adresse: this.collaborateur_adresse,
        },
      };
    },
  },
};
</script>
<style lang="css"></style>
